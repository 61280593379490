import "@/styles/globals.css";
// import global content styles from frontity site
// scoped ONLY to #bettingnews-content
import "@/styles/global-theme-content.scss";
import "@/styles/global-custom-content.scss";
import "@/styles/global-content.scss";

import { GoogleAnalytics, GoogleTagManager } from "@next/third-parties/google";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import ErrorBoundary from "@/components/errorBoundary/ErrorBoundary";
import Ticker from "@/components/Ticker";
import { IS_PROD } from "@/config/constants";
import labels from "@/config/labels";
import { AppProvider } from "@/context/AppContext";
import { GamesProvider } from "@/context/GamesContext";

import { getHorseTracks } from "./api/apiBettingsNews";

const NavFooterLayout = dynamic(
  () => import("@/components/layouts/NavFooterLayout")
);
const SidebarLayout = dynamic(
  () => import("@/components/layouts/SidebarLayout")
);

const NewsletterBox = dynamic(() => import("@/components/NewsletterBox"), {
  ssr: false,
});

function App({ Component, pageProps }) {
  const router = useRouter();
  const isHomepage = router.pathname === "/";
  const [horses, setHorses] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getHorseTracks();
      setHorses(data);
    };
    fetchData();
  }, []);

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://wp.bettingnews.com" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <link rel="dns-prefetch" href="https://wp.bettingnews.com" />
      </Head>
      <AppProvider>
        <GamesProvider>
          <NewsletterBox />
          {IS_PROD && (
            <>
              <GoogleTagManager
                gtmId={process.env.NEXT_PUBLIC_BETTING_NEWS_GTM}
              />
              <GoogleAnalytics
                gaId={process.env.NEXT_PUBLIC_BETTING_NEWS_GA4}
              />
            </>
          )}
          {!IS_PROD && (
            <div className="w-full bg-gradient-to-r from-[#dc3545] from-70% to-[#01337d] py-[1%] text-center font-bold text-white">
              {labels.stagingEnvironmentWarning}
            </div>
          )}
          <NavFooterLayout horses={horses}>
            {isHomepage ? (
              <ErrorBoundary>
                <Component {...pageProps} />
              </ErrorBoundary>
            ) : (
              <SidebarLayout siteContent={pageProps?.siteContent}>
                <ErrorBoundary>
                  <Ticker />
                  <Component {...pageProps} />
                </ErrorBoundary>
              </SidebarLayout>
            )}
          </NavFooterLayout>
        </GamesProvider>
      </AppProvider>
    </>
  );
}

export default App;
