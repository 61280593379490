import { RESTRICTED_AFFILIATES_STATES } from "@/config/constants";
import { getUserLocation } from "@/pages/api/apiAbstract";

// Format WP affiliate content / data
export async function formatAffiliates(data) {
  let userLocation;
  // commented at the moment
  if (typeof window !== "undefined") {
    userLocation = await getUserLocation();
  }

  const edges = data?.affiliates?.edges || [];
  const affiliates = [
    "BetOnline",
    "MyBookie",
    "betwhale",
    "SportsBetting",
    "Bovada",
    "sportbet",
    "BetUS",
    "everygame",
    "BetDSI",
  ];

  const filteredEdges = edges.filter((edge) => {
    const title = edge?.node?.title || "";
    const restrictions = RESTRICTED_AFFILIATES_STATES[title] || [];

    if (!userLocation || restrictions.length === 0) {
      return true;
    }
    return !restrictions.includes(userLocation);
  });

  const orderedData = filteredEdges.sort((a, b) => {
    const indexA = affiliates?.indexOf(a?.node?.title || "");
    const indexB = affiliates?.indexOf(b?.node?.title || "");
    return (
      (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB)
    );
  });

  return orderedData.map((edge) => {
    const item = edge?.node;
    if (!item) return {};

    const { id, title } = item;
    const affiliateInfo =
      {
        ...item.affiliateInfo,
      } || {};

    return {
      id,
      title,
      ...affiliateInfo,
    };
  });
}
